// 네이티브 함수로 데이터 전달 처리
const sendNativeBridge = (cmd, data = null, callback = null) => {
    if (cmd) {
        if (navigator.userAgent.search('os:android') > 0) {
            window.Android.nativeBridge(cmd, data, callback)
        } else if (navigator.userAgent.search('os:ios') > 0) {
            window.webkit.messageHandlers.nativeBridge.postMessage({
                cmd: cmd,
                data: data,
                callback: callback
            });
        }
    }
}

const nativeBridge = {
    showRiderPop: (index) => {
        sendNativeBridge('ShowRiderPop', index);
    },
};

export default nativeBridge;