<template>
  <div id="app">
    <Map/>
  </div>
</template>

<script>
import Map from './components/mapview.vue';

export default {
  name: 'App',
  mounted() {
    window.backRouter = () => {
      alert("test22")
    };
  },
  components: {
    Map, 
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
