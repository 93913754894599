<template>
  <div id="map"></div>
</template>

<script>
import nativeBridge from "../plugins/nativeBridge";
export default {
  data() {
    return {
      map: null,
      markerList: [],
      polylineList: [],
      overlayList: [],
    };
  },
  mounted() {
    window.kakao && window.kakao.maps ? this.initMap() : this.addScript();
    window.setMarkers = (markers) => {
      this.initMap()
      this.markerList = markers;
      this.addMarker(markers);
    };
    
    window.reMarkers = (markers) => {
      //마커를 모두 지우고 새로 그림?
      this.removeMakers();
      this.markerList = markers;
      this.addMarker(markers);
    }
    
    window.setPolylines = (marker, polylines) => {
      this.initMap()
      this.addMarker([marker])

      var bounds = new kakao.maps.LatLngBounds(); 

      var imageSize = new kakao.maps.Size(20, 25);
      var imageOption = {offset: new kakao.maps.Point(10, 25)};

      for (var i = 0; i < polylines.length; i++) {
        var value = polylines[i];
        var sPoint = new kakao.maps.LatLng(value.st_lat, value.st_lng)
        new kakao.maps.Marker({
          map: this.map, // 마커를 표시할 지도
          position: sPoint, // 마커를 표시할 위치
          image: new kakao.maps.MarkerImage(
            require("../assets/img/poi_marker_start.png"),
            imageSize,
            imageOption
          ) 
        });
        var ePoint = new kakao.maps.LatLng(value.ed_lat, value.ed_lng)
        new kakao.maps.Marker({
          map: this.map, // 마커를 표시할 지도
          position: ePoint, // 마커를 표시할 위치
          image: new kakao.maps.MarkerImage(
            require("../assets/img/poi_marker_end.png"),
            imageSize,
            imageOption
          )
        });

        var linePath = [
            sPoint,
            ePoint
        ];

        // 지도에 표시할 선을 생성합니다
        new kakao.maps.Polyline({
            map: this.map,
            path: linePath, // 선을 구성하는 좌표배열 입니다
            strokeWeight: 3, // 선의 두께 입니다
            strokeColor: 'rgb('+this.getRandomInt(0,255)+','+this.getRandomInt(0,255)+','+this.getRandomInt(0,255)+')', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid' // 선의 스타일입니다
        });
        bounds.extend(sPoint)
        bounds.extend(ePoint)
      }
      this.map.setBounds(bounds);
    }
  },
  methods: {
    initMap() {
      var container = document.getElementById("map");
      container.innerHTML = ""
      var lat = this.getAgentData("gps_lat")
        ? this.getAgentData("gps_lat")
        : 35.8569667;
      var lng = this.getAgentData("gps_lng")
        ? this.getAgentData("gps_lng")
        : 128.4946318;
      var center = new kakao.maps.LatLng(lat, lng);
      var options = {
        center: center,
        level: 7,
      };
      this.map = new kakao.maps.Map(container, options); 
      // var marker = new kakao.maps.Marker({ position: this.map.getCenter() });
      // marker.setMap(this.map);

      this.markerList = []
      this.polylineList = []
      this.overlayList = []
    },
    addScript() {
      const script = document.createElement("script");
      /* global kakao */ script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=7f3640486c9ee2ff4318510bc320c19b&libraries=clusterer";
      document.head.appendChild(script);
    },
    removeMakers(){
      //기사 마커 삭제
      if(this.markerList.length > 0){
        for ( var i = 0; i < this.markerList.length; i++ ) {
          this.markerList[i].setMap(null);
        }
        this.markerList = [];            
      }

      if(this.overlayList.length > 0){
        for ( var k = 0; k < this.overlayList.length; k++ ) {
          this.overlayList[k].setMap(null);
        }
        this.overlayList = [];
      }
      
    },      
    addMarker(markers) {
      let list1 = []
      let list2 = []
      var k = this.map;
      markers.forEach(function (value, index) {
        // var content =
        // '<div class="customoverlay" id="customoverlay">' +
        // '  <a onclick="onClickActionMarker('+index+'); return false;">' +
        // '    <span class="title" >'+value.name+'</span>' +
        // "  </a>" +
        // "</div>";
        var content = document.createElement("div");
        content.className = "customoverlay";
        var aTag = document.createElement("a");
        var spanTag = document.createElement("span");
        spanTag.className = "title";
        spanTag.appendChild(document.createTextNode(value.nick));
        aTag.appendChild(spanTag)
        content.appendChild(aTag);

        content.onclick = function() {
           nativeBridge.showRiderPop(index);
        };

        var latlng = value.gps.latlng.split(',');
        var imageSize = new kakao.maps.Size(33, 45);
        var imageOption = {offset: new kakao.maps.Point(17, 45)};
        var position = new kakao.maps.LatLng(latlng[0], latlng[1]) //

        // 마커 이미지를 생성합니다    /img/marker.bebbc4e2.png
        var markerImage = new kakao.maps.MarkerImage(
          require("../assets/img/marker.png"),
          imageSize,
          imageOption
        );

        // 마커를 생성합니다
        var marker = new kakao.maps.Marker({
          map: k, // 마커를 표시할 지도
          position: position, // 마커를 표시할 위치
          title: value.nick, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
          image: markerImage, // 마커 이미지
        });

        var overlay = new kakao.maps.CustomOverlay({
          map: k,
          position: position,
          content: content,
        });
        list1.push(marker)
        list2.push(overlay)
      });
      this.markerList = list1;
      this.overlayList = list2;

      // var clusterer = new kakao.maps.MarkerClusterer({
      //     map: this.map, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
      //     averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
      //     minLevel: 5 // 클러스터 할 최소 지도 레벨 
      // });
      // clusterer.addMarkers(this.markerList);
      // kakao.maps.event.addListener( clusterer, 'clustered', function( clusters ) {
      //    console.log( clusters.length );
      // });

      // kakao.maps.event.addListener( clusterer, 'clusterout', function( cluster ) {
      //     console.log( cluster.getSize() );
      // });
    },
    getAgentData(key) {
      let parserKey = `,${key}:`;
      let retData = "";
      if (navigator.userAgent.search(parserKey) != -1) {
        retData = navigator.userAgent.substring(
          navigator.userAgent.indexOf(parserKey) + parserKey.length
        );
        retData = retData.substring(0, retData.indexOf(","));
      }
      return retData;
    },

    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#map {
  position: absolute;
  width: 100%;
  height: 100%;
}
body {
  margin: 0px !important;
}
.customoverlay {
  position: relative;
  bottom: 70px;
  border-radius: 6px;
  border: 1px solid #ccc;
  border-bottom: 2px solid #ddd;
  float: left;
}
.customoverlay:nth-of-type(n) {
  border: 0;
  box-shadow: 0px 1px 2px #888;
}
.customoverlay a {
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  /* background: #d95050;
  background: #d95050
    url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png)
    no-repeat right 14px center; */
}
.customoverlay .title {
  display: block;
  text-align: center;
  background: #fff;
  /* margin-right: 35px; */
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
.customoverlay:after {
  content: "";
  position: absolute;
  margin-left: -12px;
  left: 50%;
  bottom: -12px;
  width: 22px;
  height: 12px;
  background: url("https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png");
}
</style>
